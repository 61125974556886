const data = [
    {
        text: '',
        sortable: false,
        value: 'select',
        align: 'center',
        minimal: true,
        width: '25px',
        permissions: ['VE', 'A', 'PO', 'BA', 'BB', 'BD']
    },
    {
        text: 'Mitarbeiter',
        sortable: false,
        value: 'employee_id',
        minimal: true,
        align: 'center',
        width: '125px',
    },
    {
        text: 'Tag',
        sortable: false,
        value: 'date',
        minimal: true,
        align: 'center',
        width: '50px',
    },
    {
        text: 'Tagwerk',
        sortable: false,
        value: 'work_time',
        minimal: true,
        align: 'center',
        width: '50px',
    },
    {
        text: 'Baustelle',
        sortable: false,
        value: 'construction_zone_id',
        minimal: true,
        align: 'center',
        width: '120px',
    },
    {
        text: 'Bundesland',
        sortable: false,
        value: 'state',
        minimal: true,
        align: 'center',
        width: '100px',
    },
    {
        text: 'Einsatzort',
        sortable: false,
        value: 'work_place',
        minimal: true,
        align: 'center',
        width: '80px',
    },
    {
        text: 'Anwesenheit (von-bis)',
        sortable: false,
        minimal: true,
        value: 'work_range',
        width: '140px',
        align: 'center',
    },
    {
        text: 'Pause (von-bis)',
        sortable: false,
        minimal: true,
        value: 'pause_range',
        width: '140px',
        align: 'center',
    },
    {
        text: '',
        sortable: false,
        value: 'copy',
        cellClass: 'vertical-bottom',
        align: 'center',
        minimal: true,
        width: '40px',
    },
    {
        text: '',
        sortable: false,
        value: 'add',
        cellClass: 'vertical-bottom',
        align: 'center',
        minimal: true,
        width: '40px',
    },
    {
        text: '',
        sortable: false,
        value: 'remove',
        cellClass: 'vertical-bottom',
        align: 'center',
        minimal: true,
        width: '40px',
    },
    {
        text: 'Wegstreckenentschädigung',
        sortable: false,
        value: 'compensation',
        align: 'center',
        width: '70px',
    },
    {
        text: 'Abwesenheit',
        sortable: false,
        value: 'missing',
        align: 'center',
        width: '100px',
    },
    // {
    //     text: 'Krank',
    //     sortable: false,
    //     value: 'illness',
    //     align: 'left',
    //     width: '70px',
    // },
    {
        text: 'Verpflegungspauschale',
        sortable: false,
        value: 'meal_allowance',
        align: 'center'
    },
    {
        text: 'VPStpfl',
        sortable: false,
        value: 'meal_allowance_taxable',
        align: 'center',
        permissions: ['VE', 'A']
    },
    {
        text: 'Feiertag',
        sortable: false,
        value: 'holidays_enabled',
        align: 'center',
        width: '70px',
    },
    {
        text: 'Feiertag',
        sortable: false,
        value: 'holiday',
        align: 'left',
        width: '70px',
        permissions: ['VE', 'A']
    },
    // {
    //     text: 'Zeitkonto',
    //     sortable: false,
    //     value: 'time_account',
    //     minimal: false,
    //     align: 'center',
    //     permissions: ['VE', 'A']
    // },
    // {
    //     text: 'Abw. sonstig',
    //     sortable: false,
    //     value: 'missing_reason_additional',
    //     minimal: false,
    //     align: 'left'
    // },
    {
        text: 'Sonntags-zuschlag',
        sortable: false,
        value: 'sunday',
        minimal: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'Nachtschicht',
        sortable: false,
        value: 'night_time',
        minimal: false,
        align: 'center'
    },
    {
        text: 'Anreise Privat',
        sortable: false,
        value: 'privat_car',
        align: 'center'
    },
    {
        text: 'Anreiseschlüssel',
        sortable: false,
        value: 'arrival_key',
        align: 'center',
        permissions: ['VE', 'A']
    },
    {
        text: 'Kosten',
        sortable: false,
        value: 'costs',
        align: 'center',
        width: '70px',
        permissions: ['VE', 'A']
    },
    {
        text: 'Unterkunft privat',
        sortable: false,
        value: 'accommodation',
        align: 'center'
    },
    {
        text: 'Auslöseschlüssel',
        sortable: false,
        value: 'release_key',
        align: 'center',
        permissions: ['VE', 'A']
    },
    {
        text: 'Zulage',
        sortable: false,
        value: 'bonus',
        align: 'center',
        permissions: ['VE', 'A']
    },
    {
        text: 'TKZ',
        sortable: false,
        value: 'activity_kz',
        width: '60px',
        align: 'center',
        hint: 'Tätigkeitskennzeichen'
    },
    {
        text: 'Approved by',
        sortable: false,
        value: 'approved_by',
        width: '60px',
        align: 'center',
    },
]

export default data;