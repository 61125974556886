import {workLogs} from './../graphql/worklog'
import {employeesList} from './../graphql/employee'

export default {
    data() {
        return {
            tableData: [],
            rendering: true,
            defaultRow: {
                date: null,
                work_minutes: null,
                construction_zone_id: null,
                work_place: '',
                activity_kz: null,
                work_start: null,
                work_end: null,
                pause_start: null,
                pause_end: null,
                drive_status: null,
                drive_time: null,
                missing_reason: null,
                missing_time: null,
                illness: null,
                illness_time: null,
                work_on_sunday_time: null,
                work_on_night_time: null,
                dirty: false,
            },
        }
    },
    methods: {
        calculateData(callback = null) {
            this.rendering = true
            const selectedDate = this.year + '-' + this.month + '-' + this.day

            if (this.listMode) {
                if(!this.filter.department_id && ! this.filter.construction_zone_id) {
                    if (callback) {
                        callback()
                    }
                    this.rendering = false
                    return;
                }
                this.$apollo.query({
                    query: employeesList,
                    variables: {
                        date:  this.year + '-' + this.month + '-' + this.day,
                        construction_zone_id: this.filter.construction_zone_id,
                        department_id: this.filter.department_id,
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.employeesList = data.employeesList.map(employee => {
                        return {...employee, label: employee.firstname + ' ' + employee.lastname + ' ' + employee.personal_number}
                    })

                    this.$apollo.query({
                        query: workLogs,
                        variables: {
                            date: selectedDate,
                            employee_ids: this.employeesList.map(employee => employee.id)
                        },
                        fetchPolicy: 'network-only'
                    }).then((result) => {
                        let workLogs = {}
                        result.data.workLogs.forEach(worklog => {
                            if (!workLogs[worklog.employee_id]) {
                                workLogs[worklog.employee_id] = []
                            }

                            workLogs[worklog.employee_id].push(worklog)
                        })

                        this.tableData = []
                        this.employeesList.forEach(employee => {
                            if (workLogs[employee.id]) {
                                let items = []
                                workLogs[employee.id].forEach(worklog => {
                                    items.push({...worklog, date: selectedDate, employee_id: employee.id})
                                })
                                this.tableData.push({
                                    employee_id: employee.id,
                                    date: selectedDate,
                                    items: items,
                                })
                            } else {
                                this.tableData.push({
                                    employee_id: employee.id,
                                    date: selectedDate,
                                    items: [{
                                        ...this.defaultRow,
                                        date: selectedDate,
                                        employee_id: employee.id,
                                        activity_kz: this.getEmployeeById(employee.id).activity_kz ?? '1'
                                    }],
                                })
                            }
                        })
                    }).finally(() => {
                        if (callback) {
                            callback()
                        }
                        this.rendering = false
                    })
                })


            } else {

                this.$apollo.query({
                    query: workLogs,
                    variables: {
                        date: selectedDate,
                        employee_id:  this.selected_employee_id,
                    },
                    fetchPolicy: 'network-only'
                }).then((result) => {
                    let workLogs = {}
                    result.data.workLogs.forEach(worklog => {
                        if (!workLogs[worklog.date]) {
                            workLogs[worklog.date] = []
                        }

                        workLogs[worklog.date].push(worklog)
                    })

                    let data = []
                    this.days.forEach(date => {
                        if (workLogs[date]) {
                            let items = []
                            workLogs[date].forEach(worklog => {
                                items.push({...worklog, date: date, employee_id: this.selected_employee_id})
                            })
                            data.push({
                                date: date,
                                items: items,
                            })
                        } else {
                            data.push({
                                date: date,
                                items: [{
                                    ...this.defaultRow,
                                    date: date,
                                    employee_id: this.selected_employee_id,
                                    activity_kz: this.getEmployeeById(this.selected_employee_id).activity_kz ?? '1'
                                }],
                            })
                        }
                    })

                    this.tableData = data
                }).finally(() => {
                    if (callback) {
                        callback()
                    }
                    this.rendering = false
                })
            }
        },
    },
    computed: {
        filteredItems() {
            if (this.opened) {
                let data = this.tableData.filter(day => {

                    if (day.items.length == 1 && !day.items[0].id) {
                        return true
                    }

                    return false
                })

                return data
            }

            return this.tableData
        },
    },
}