<template>
    <div>
        <zw-autocomplete v-model="subItem.construction_zone_id"
                         :items="filteredZones"
                         @input="onInput"
                         item-text="label"
                         :custom-option-label="true"
                         item-value="id"
                         dense outlined hide-details
                         :readonly="readonly"
                         :clearable="!readonly"
                         :name="name"
                         :rules="{required:(Boolean(subItem.dirty) && !subItem.missing_reason )}"
        ></zw-autocomplete>
    </div>
</template>

<script>
    export default {
        name: 'SlotConstructionZone',
        props: {
            name: String,
            subItem: Object,
            constructionZonesList: Array,
            readonly: Boolean,
            employee: Object
        },
        methods: {
            setDirty() {
                this.$set(this.subItem, 'dirty', true)
            },
            constructionZoneSelected(val) {
                const constructionZone = this.constructionZonesList.filter(zone => {
                    return zone.id == val
                })[0]
                if (constructionZone) {
                    this.$set(this.subItem, 'state', constructionZone.state)
                }
            },
            onInput(val) {
                this.setDirty()
                this.constructionZoneSelected(val)
            }
        },
        computed: {
            filteredZones: function () {
                if(this.employee == null) {
                    return []
                }
                return this.constructionZonesList.filter(zone => zone.company_id == this.employee.company_id)
            }
        }
    }
</script>