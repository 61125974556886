<template>
    <v-data-table
            :headers="mobileHeaders"
            :items="items"
            :server-items-length="items.length"
            :loading="loading"
            class="elevation-1"
            hide-default-footer
            :mobile-breakpoint="0"
            :fixed-header="true"
            :height="tableHeight+'px'"
    >
        <template v-slot:item.date="{ item }">
            {{ item.date | dateFormat }}
        </template>
        <template v-slot:item.work_time="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'work_time')"
                 v-for="(subItem, index) in item.items" :key="index">
                {{ (subItem.work_minutes / 60).toFixed(2) }}
            </div>
        </template>
        <template v-slot:item.construction_zone_id="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'construction_zone_id')"
                 v-for="(subItem, index) in item.items" :key="index">
                {{ getConstructionZoneName(subItem.construction_zone_id) }}
            </div>
        </template>
        <template v-slot:item.actions="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'actions')"
                 v-for="(subItem, index) in item.items"
                 :key="index">
                <v-btn @click="$root.$children[0].$refs['worklog-form'].open(subItem.id, subItem.employee_id, item.date, true)"
                       small icon>
                    <v-icon>
                        mdi-eye
                    </v-icon>
                </v-btn>
                <v-btn
                        @click="$root.$children[0].$refs['worklog-form'].open(subItem.id, subItem.employee_id,item.date, false, callback)"
                        small
                        v-if="!isReadOnly(subItem)"
                        :disabled="isReadOnly(subItem)"
                        icon color="success">
                    <v-icon>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </div>
        </template>
    </v-data-table>
</template>

<script>
    import headersData from '@/headers/timeManagement'
    import worklogsCalculations from '@/mixins/worklogsCalculations'
    import moment from 'moment'

    export default {
        name: 'TimeTableMobile',
        mixins: [worklogsCalculations],
        props: {
            items: {
                type: Array,
                default: []
            },
            loading: {
                type: Boolean,
            },
            constructionZonesList: {
                type: Array,
            },
            callback: {
                type: Function
            }
        },
        data() {
            return {
                tableHeight: 200,
                mobileHeaders: [
                    {
                        text: 'Tag',
                        sortable: false,
                        value: 'date',
                        align: 'center'
                    },
                    {
                        text: 'Tagwerk',
                        sortable: false,
                        value: 'work_time',
                        align: 'center'
                    },
                    {
                        text: 'Baustelle',
                        sortable: false,
                        value: 'construction_zone_id',
                        align: 'center',
                        width: '120px',
                    },
                    {
                        text: '',
                        sortable: false,
                        value: 'actions',
                        align: 'center',
                        width: '100px'
                    },
                ],
            }
        },
        methods: {
            getConstructionZoneName(id) {
                let constructionZone = this.constructionZonesList.filter(item => {
                    return item.id == id
                })[0];
                if (constructionZone) {
                    return constructionZone.name
                }

                return '-'
            },
            getMe() {
                return this.$root.$children[0].me;
            },
        },
        updated: function () {
            let tableWrapper = document.getElementsByClassName("custom-table").item(0)
            if (tableWrapper) {
                let top = tableWrapper.getBoundingClientRect().top
                let height = window.innerHeight - top - 12
                this.tableHeight = height > 800 ? height : 800
            }
        },
    }
</script>