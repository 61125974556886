<template>
    <div class="d-inline-flex">
        <div class="pr-2">
            <validation-provider
                    v-slot="{ errors }"
                    :name="name"
                    :rules="{'timeRanges':[item.items,index]}"
            >
                <zw-time2 v-model="subItem.work_start"
                          @change="onChange"
                          @input="setDirty"
                          :max="subItem.work_end"
                          :readonly="readonly"
                          :error-messages="errors"
                          :allowed-minutes="allowedStep"
                ></zw-time2>
            </validation-provider>
        </div>
        <div>
            <validation-provider
                    v-slot="{ errors }"
                    :name="'work_end'+index+item.date"
                    :rules="{'timeRanges':[item.items, index]}"
            >
                <zw-time2 v-model="subItem.work_end"
                          @change="onChange"
                          @input="setDirty"
                          :min="subItem.work_start"
                          :readonly="readonly"
                          :error-messages="errors"
                          :allowed-minutes="allowedStep"
                ></zw-time2>
            </validation-provider>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SlotWorkRange',
        props: {
            index: [String, Number],
            name: String,
            item: Object,
            subItem: Object,
            readonly: Boolean
        },
        methods: {
            setDirty() {
                this.$set(this.subItem, 'dirty', true)
            },
            allowedStep: m => m % 15 === 0,
            onChange() {
                this.$emit('calculate', this.subItem)
            }
        }
    }
</script>