import moment from "moment";

export default {
    data() {
        return {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
        }
    },
    computed: {
        years: function () {
            const employee = this.getCurrentEmployee()

            const startYear = (employee && employee.start_date ? employee.start_date.split('-')[0] : 2022) - 0
            let years = []
            for (let i = startYear; i <= new Date().getFullYear(); i++) {
                years.push(i)
            }
            return years
        },
        months: function () {
            const employee = this.getCurrentEmployee()

            let months = []
            let firstMonth = 0
            let monthsCount = 12
            if (this.year == new Date().getFullYear()) {
                monthsCount = new Date().getMonth() + 1
            }

            if (employee && employee.start_date && this.year == employee.start_date.split('-')[0]) {
                firstMonth = employee.start_date.split('-')[1] - 1
            }

            for (let i = firstMonth; i < monthsCount; i++) {
                months.push({
                    'text': new Date(this.year + '-' + (i + 1 < 10 ? '0' : '') + (i + 1) + '-01').toLocaleString('de', {month: 'long'}),
                    'value': i + 1,
                })
            }

            return months
        },
        days: function () {
            const employee = this.getCurrentEmployee()

            let days = []
            let firstDay = 1
            let daysInMonth = new Date(this.year, this.month, 0).getDate()

            // if (this.year == new Date().getFullYear() && this.month == new Date().getMonth() + 1) {
            //     daysInMonth = new Date().getDate()
            // }

            if (this.year == employee.start_date.split('-')[0] && this.month == parseInt(employee.start_date.split('-')[1])) {
                firstDay = employee.start_date.split('-')[2]
            }

            for (let i = firstDay; i <= daysInMonth; i++) {
                days.push(moment(new Date(this.year, this.month - 1, i)).format('YYYY-MM-DD'))
            }

            return days
        },
        daysList: function () {
            let days = []
            let firstDay = 1
            let daysInMonth = new Date(this.year, this.month, 0).getDate()

            for (let i = firstDay; i <= daysInMonth; i++) {
                days.push({
                    'text': i,
                    'value': i,
                })
            }

            return days
        },
    },
    methods: {
        getMe() {
            return this.$root.$children[0].me;
        },
        getEmployeeById(id) {
            if(id == this.getMe().id) {
                return this.getMe()
            }
            let employee = this.employeesList.find(employee => employee.id == id) || null

            if(!employee) {
                employee = this.employeesListSearch.find(employee => employee.id == id) || null
            }


            return employee
        },
        getCurrentEmployee() {
            if(this.selected_employee_id == this.getMe().id) {
                return this.getMe()
            }
            const employee = this.employeesList.find(employee => employee.id == this.selected_employee_id) || null

            return employee
        },
        yearChanged(year) {
            // Prevent future month
            if (new Date().getFullYear() == year) {
                if (this.month > new Date().getMonth() + 1) {
                    this.month = new Date().getMonth() + 1
                }
            }

            this.calculateData()
        },
        monthChanged() {
            this.calculateData()
        },
        dayChanged() {
            this.calculateData()
        },
    },
}