<template>
    <v-data-table
            :headers="columns"
            :items="items"
            :loading="loading"
            class="elevation-1"
            hide-default-footer
            :item-class="itemRowBackground"
            :items-per-page="items.length"
            :mobile-breakpoint="0"

    >
        <template v-slot:header.select="{ header }">
            <v-checkbox class="align-center justify-center mt-1"
                        hide-details
                        @change="selectAll"
            ></v-checkbox>
        </template>
        <template v-slot:header.activity_kz="{ header }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on"
                    >{{header.text}}
                    </div>
                </template>
                <span>{{header.hint}}</span>
            </v-tooltip>
        </template>
        <template v-slot:header.missing="{ header }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on"
                    >{{header.text}}
                    </div>
                </template>

                <div v-for="option in $root.$children[0].getOptionsWithValue('missing', null, true)">
                    <strong>{{option.id}}</strong> - {{option.label}}
                </div>
            </v-tooltip>
        </template>
        <template v-slot:header.compensation="{ header }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on"
                    >{{header.text}}
                    </div>
                </template>

                <div v-for="option in $root.$children[0].getOptionsWithValue('zone', null, true)">
                    <strong>{{option.id}}</strong> - {{option.label}}
                </div>
            </v-tooltip>
        </template>
        <template v-slot:header.holiday="{ header }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on"
                    >{{header.text}}
                    </div>
                </template>
                <div v-for="option in $root.$children[0].getOptionsWithValue('holiday', null, true)">
                    <strong>{{option.id}}</strong> - {{option.label}}
                </div>
            </v-tooltip>
        </template>
        <template v-slot:header.state="{ header }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs"
                         v-on="on"
                    >{{header.text}}
                    </div>
                </template>
                <div v-for="option in $root.$children[0].getOptionsWithValue('state', null, true)">
                    <strong>{{option.id}}</strong> - {{option.label}}
                </div>
            </v-tooltip>
        </template>
        <template v-slot:item.select="{ item }">
            <div class="table-item"
                 v-for="(subItem, index) in item.items"
                 :key="index">
                <v-checkbox v-if="!isApproved(subItem)"
                            v-model="selectedIds"
                            class="align-center justify-center"
                            hide-details
                            :value="subItem.id ? subItem.id : (subItem.date + '|' + subItem.employee_id)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.date="{ item }">
            {{ item.date | dateFormat }}
        </template>
        <template v-slot:item.employee_id="{ item }">
            {{ getEmployeeName(item.employee_id) }}
        </template>
        <template v-slot:item.work_time="{ item }">
            <div class="table-item text" :class="itemClasses(subItem, 'work_minutes')"
                 v-for="(subItem, index) in item.items" :key="index">
                {{subItem.work_minutes | toHours}}
            </div>
        </template>
        <template v-slot:item.construction_zone_id="{ item }">
            <slot-construction-zone v-for="(subItem, index) in item.items"
                                    :key="index"
                                    :sub-item="subItem"
                                    :name="'construction_zone_id'+index+item.date"
                                    :construction-zones-list="constructionZonesList"
                                    :readonly="isReadOnly(subItem)"
                                    class="table-item"
                                    :class="itemClasses(subItem, 'construction_zone_id')"
                                    :employee="getEmployeeById(subItem.employee_id)"
            ></slot-construction-zone>
        </template>
        <template v-slot:item.state="{ item }">
            <slot-state v-for="(subItem, index) in item.items"
                        :key="index"
                        :sub-item="subItem"
                        class="table-item"
                        :class="itemClasses(subItem, 'state')"
                        :readonly="isReadOnly(subItem)"
                        :name="'state'+index+item.date"
            ></slot-state>
        </template>
        <template v-slot:item.work_place="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'work_place')"
                 v-for="(subItem, index) in item.items" :key="index">
                <validation-provider
                        v-slot="{ errors }"
                        :name="'work_place'+index+item.date"
                        :rules="{required:(Boolean(subItem.dirty) && !subItem.missing_reason)}"
                >
                    <v-text-field v-model="subItem.work_place"
                                  @input="setDirty(subItem)"
                                  dense outlined hide-details
                                  :error-messages="errors"
                                  :readonly="isReadOnly(subItem)"
                    ></v-text-field>
                </validation-provider>
            </div>
        </template>
        <template v-slot:item.activity_kz="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'activity_kz')"
                 v-for="(subItem, index) in item.items" :key="index">
                <validation-provider
                        v-slot="{ errors }"
                        :name="'activity_kz_'+index+item.date"
                        :rules="{required:(Boolean(subItem.dirty) && !subItem.missing_reason)}"
                >
                    <v-select v-model="subItem.activity_kz"
                              :items="$root.$children[0].getOptionsWithValue('tkz', subItem.activity_kz)"
                              item-text="label"
                              item-value="id"
                              @input="setDirty(subItem)"
                              dense
                              outlined
                              :error-messages="errors"
                              hide-details
                              :readonly="isReadOnly(subItem)"
                              single-line
                    ></v-select>
                </validation-provider>
            </div>
        </template>
        <template v-slot:item.work_range="{ item }">
            <slot-work-range v-for="(subItem, index) in item.items"
                             :key="index"
                             :index="index"
                             :item="item"
                             :sub-item="subItem"
                             :readonly="isReadOnly(subItem)"
                             class="table-item"
                             :class="itemClasses(subItem, 'work')"
                             @calculate="calculateWorkingTime(subItem)"
            ></slot-work-range>
        </template>
        <template v-slot:item.pause_range="{ item }">
            <slot-pause v-for="(subItem, index) in item.items"
                        :key="index"
                        class="table-item"
                        :class="itemClasses(subItem, 'pause')"
                        :sub-item="subItem"
                        :readonly="isReadOnly(subItem)"
                        @calculate="calculateWorkingTime(subItem)"
            ></slot-pause>
        </template>
        <template v-slot:item.compensation="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'compensation')"
                 v-for="(subItem, index) in item.items"
                 :key="index">
                <div class="d-inline-flex">
                    <div class="pr-2">
                        <v-select v-model="subItem.compensation_zone"
                                  :items="$root.$children[0].getOptionsWithValue('zone', subItem.compensation_zone, true)"
                                  @input="setDirty(subItem)"
                                  dense outlined hide-details
                                  :readonly="isReadOnly(subItem)"
                                  item-text="label"
                                  item-value="id"
                                  :clearable="!isReadOnly(subItem)"
                                  single-line
                        ></v-select>
                    </div>
                    <div v-if="['VE', 'A'].includes(getMe().user.role)" class="pr-2"
                         style="min-width: 70px">
                        <zw-input v-model="subItem.compensation_payment_type"
                                  @input="setDirty(subItem)"
                                  dense outlined hide-details
                                  :readonly="isReadOnly(subItem)"
                                  type="number"
                                  min="0"
                                  :rules="{min_value:0}"
                        ></zw-input>
                    </div>
                    <div v-if="['VE', 'A'].includes(getMe().user.role)" style="min-width: 70px">
                        <zw-input v-model="subItem.compensation_amount"
                                  @input="setDirty(subItem)"
                                  dense outlined hide-details
                                  :readonly="isReadOnly(subItem)"
                                  type="number"
                                  min="0"
                                  :rules="{min_value:0}"
                        ></zw-input>
                    </div>
                </div>
            </div>
        </template>
        <!--  Urlaub-->
        <template v-slot:item.missing="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'reason')"
                 v-for="(subItem, index) in item.items"
                 :key="index">
                <div class="d-inline-flex">
                    <div class="pr-2">
                        <v-select v-model="subItem.missing_reason"
                                  :items="$root.$children[0].getOptionsWithValue('missing', subItem.missing_reason, false, subItem, constructionZonesList)"
                                  item-text="label"
                                  item-value="id"
                                  @input="()=>{
                                                          setDirty(subItem)
                                                          missingReasonChanged(subItem)
                                                          }"
                                  dense outlined hide-details
                                  :readonly="isReadOnly(subItem)"
                                  :clearable="!isReadOnly(subItem)"
                                  single-line
                        ></v-select>
                    </div>
                    <div v-if="subItem.missing_reason" style="min-width: 70px">
                        <zw-time-field v-model="subItem.missing_time"
                                       @input="()=>{
                                                               setDirty(subItem)
                                                               missingTimeChanged(subItem)
                                                               }"
                                       rules="min_value:0"
                                       :min="0"
                                       :readonly="isReadOnly(subItem)"
                        ></zw-time-field>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:item.holidays_enabled="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'holidays_enabled')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-checkbox v-model="subItem.holidays_enabled"
                            @change="setDirty(subItem)"
                            class="align-center justify-center mt-1"
                            hide-details
                            :readonly="isReadOnly(subItem)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.holiday="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'holiday')"
                 v-for="(subItem, index) in item.items"
                 :key="index">
                <div class="d-inline-flex">
                    <div class="pr-2">
                        <v-select v-model="subItem.holiday"
                                  v-if="subItem.holidays_enabled"
                                  :items="$root.$children[0].getOptionsWithValue('holiday', subItem.holiday)"
                                  item-text="label"
                                  item-value="id"
                                  @input="()=>{
                                                          setDirty(subItem)
                                                          }"
                                  dense outlined hide-details
                                  :readonly="isReadOnly(subItem)"
                                  :clearable="!isReadOnly(subItem)"
                                  single-line
                        ></v-select>
                    </div>
                    <div v-if="subItem.holiday" style="min-width: 70px">
                        <zw-time-field v-model="subItem.holiday_time"
                                       v-if="subItem.holidays_enabled"
                                       @input="()=>{
                                                               setDirty(subItem)
                                                               }"
                                       :readonly="isReadOnly(subItem)"
                                       rules="min_value:0"
                                       :min="0"
                        ></zw-time-field>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:item.copy="{ item }">
            <div class="table-item">
                <v-btn small v-if="item.items.length && !isReadOnly(item.items[0])"
                       @click="copyItem(item)" color="success">
                    <v-icon>
                        mdi-content-copy
                    </v-icon>
                </v-btn>
            </div>
        </template>
        <template v-slot:item.add="{ item }">
            <div class="table-item">
                <v-btn small v-if="item.items.length && !isReadOnly(item.items[0])"
                       @click="addSubItem(item)" color="primary">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </div>
        </template>
        <template v-slot:item.remove="{ item }">
            <div class="table-item" v-for="(subItem, index) in item.items" :key="index">
                <v-btn small v-if="!isReadOnly(subItem)" @click="deleteSubItem(item, subItem)"
                       color="accent">
                    <v-icon>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </div>
        </template>

        <template v-slot:item.sunday="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'work_on_sunday_time')"
                 v-for="(subItem, index) in item.items" :key="index">
                <zw-time-field v-if="isSunday(subItem.date)"
                               v-model="subItem.work_on_sunday_time"
                               @input="setDirty(subItem)"
                               :readonly="isReadOnly(subItem)"
                ></zw-time-field>
            </div>
        </template>
        <template v-slot:item.night_time="{ item }">
            <div class="table-item text"
                 :class="itemClasses(subItem, 'work_on_night_time')"
                 v-for="(subItem, index) in item.items"
                 :key="index"
            >
                <zw-time-field v-model="subItem.work_on_night_time"
                               v-if="['VE', 'A'].includes(getMe().user.role)"
                               @input="setDirty(subItem)"
                               :readonly="isReadOnly(subItem)"
                ></zw-time-field>
                <div v-else>{{subItem.work_on_night_time|toHours}}</div>
            </div>
        </template>
        <template v-slot:item.meal_allowance="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'meal_allowance')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-checkbox v-model="subItem.meal_allowance"
                            @change="setDirty(subItem)"
                            class="align-center justify-center mt-1"
                            hide-details
                            :readonly="isReadOnly(subItem)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.meal_allowance_taxable="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'meal_allowance_taxable')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-checkbox v-model="subItem.meal_allowance_taxable"
                            @change="setDirty(subItem)"
                            class="align-center justify-center mt-1"
                            hide-details
                            :readonly="isReadOnly(subItem)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.privat_car="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'privat_car')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-checkbox v-model="subItem.privat_car"
                            @change="setDirty(subItem)"
                            class="align-center justify-center mt-1"
                            hide-details
                            :readonly="isReadOnly(subItem)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.arrival_key="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'arrival_key')"
                 v-for="(subItem, index) in item.items" :key="index">
                <zw-input v-model="subItem.arrival_key"
                          v-if="subItem.privat_car"
                          @input="setDirty(subItem)"
                          dense outlined hide-details
                          :readonly="isReadOnly(subItem)"
                          type="number"
                          min="0"
                          :rules="{min_value:0,max:4}"
                ></zw-input>
            </div>
        </template>
        <template v-slot:item.costs="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'costs')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-text-field v-model="subItem.costs"
                              v-if="subItem.privat_car"
                              type="number"
                              min="0"
                              @input="setDirty(subItem)"
                              dense outlined hide-details
                              :readonly="isReadOnly(subItem)"
                ></v-text-field>
            </div>
        </template>
        <template v-slot:item.accommodation="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'accommodation')"
                 v-for="(subItem, index) in item.items" :key="index">
                <v-checkbox v-model="subItem.accommodation"
                            @change="setDirty(subItem)"
                            hide-details
                            class="align-center justify-center mt-1"
                            :readonly="isReadOnly(subItem)"
                ></v-checkbox>
            </div>
        </template>
        <template v-slot:item.release_key="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'release_key')"
                 v-for="(subItem, index) in item.items" :key="index">
                <zw-input v-model="subItem.release_key"
                          v-if="subItem.accommodation"
                          @input="setDirty(subItem)"
                          dense outlined hide-details
                          :readonly="isReadOnly(subItem)"
                          type="number"
                          min="0"
                          :rules="{min_value:0,max:4}"
                ></zw-input>
            </div>
        </template>
        <template v-slot:item.bonus="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'release_key')"
                 v-for="(subItem, index) in item.items" :key="index">
                <zw-time-field v-model="subItem.bonus"
                               v-if="subItem.accommodation"
                               @input="setDirty(subItem)"
                               :readonly="isReadOnly(subItem)"
                               rules="min_value:0"
                               :min="0"
                ></zw-time-field>
            </div>
        </template>
        <template v-slot:item.approved_by="{ item }">
            <div class="table-item" :class="itemClasses(subItem, 'approved_by')"
                 v-for="(subItem, index) in item.items" :key="index">
                <div v-if="subItem.foremanApproved">{{subItem.foremanApproved.firstname.substring(0, 1)}}{{subItem.foremanApproved.lastname.substring(0, 1)}}</div>
                <div v-else-if="subItem.managerApproved">{{subItem.managerApproved.firstname.substring(0, 1)}}{{subItem.managerApproved.lastname.substring(0, 1)}}</div>
                <div v-else-if="subItem.adminApproved">{{subItem.adminApproved.firstname.substring(0, 1)}}{{subItem.adminApproved.lastname.substring(0, 1)}}</div>
            </div>
        </template>
        <template slot="body.append">
            <tr>
                <th>Total</th>
                <th v-if="listMode">
                    <zw-autocomplete label="Mitarbeiter"
                                     v-model="employeeSearch"
                                     class="employee-search"
                                     item-text="label"
                                     item-value="id"
                                     clearable
                                     :items="employeesListSearchFiltered"
                                     @input="employeeFound"
                    ></zw-autocomplete>
                </th>
                <th>{{totalHours}}</th>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import headersData from '@/headers/timeManagement'
    import worklogsCalculations from '@/mixins/worklogsCalculations'
    import moment from 'moment'
    import SlotConstructionZone from "./slots/SlotConstructionZone";
    import SlotState from "./slots/SlotState";
    import SlotWorkRange from "./slots/SlotWorkRange";
    import SlotPause from "./slots/SlotPause";

    export default {
        name: 'TimeTable',
        components: {SlotPause, SlotWorkRange, SlotState, SlotConstructionZone},
        mixins: [worklogsCalculations],
        props: {
            detailed: {
                type: Boolean,
                default: false
            },
            listMode: {
                type: Boolean,
                default: false
            },
            items: {
                type: Array,
                default: []
            },
            loading: {
                type: Boolean,
            },
            constructionZonesList: {
                type: Array,
            },
            employeesList: {
                type: Array,
            },
            employeesListSearch: {
                type: Array,
            },
        },
        data() {
            return {
                headers: headersData,
                selectedIds: [],
                deletedIds: [],
                employeeSearch: null,
            }
        },
        methods: {
            employeeFound(value) {
                if (value) {
                    this.$emit('append-employee', value)

                    this.$nextTick(function () {
                        this.$set(this, 'employeeSearch', null)
                    })
                }
            },
            selectAll(val) {
                if (val) {
                    this.items.map(day => {
                        day.items.forEach(subItem => {
                            if (!this.isApproved(subItem)) {
                                this.selectedIds.push(subItem.id ? subItem.id : subItem.date + '|' + subItem.employee_id)
                            }
                        })
                    })
                } else {
                    this.selectedIds = []
                }
            },
            allowedStep: m => m % 15 === 0,
            itemRowBackground: function (item) {
                let classes = []

                if (this.isWeekend(item.date)) {
                    classes.push('weekend')
                }

                if (item && this.$refs.observer) {
                    const error = Object.keys(this.$refs.observer.errors).find(key => {
                        return key.endsWith(item.date) && this.$refs.observer.errors[key].length
                    })
                    if (error) {
                        classes.push('error-row')
                    }
                }

                return classes.join(' ')
            },
            setDirty(item) {
                this.$set(item, 'dirty', true)
            },
            constructionZoneSelected(val, item) {
                const constructionZone = this.constructionZonesList.filter(zone => {
                    return zone.id == val
                })[0]
                if (constructionZone) {
                    item.state = constructionZone.state
                }
            },
            missingReasonChanged(subItem) {
                if (subItem.missing_reason) {
                    subItem.work_start = null
                    subItem.work_end = null
                    subItem.pause_start = null
                    subItem.pause_end = null
                }

                this.calculateWorkingTime(subItem)
            },
            missingTimeChanged(subItem) {
                this.calculateWorkingTime(subItem)
            },
            copyItem(item) {

                let nextDate = moment(item.date).add(1, 'day').format('YYYY-MM-DD')
                let nextEmployee = null

                if (this.listMode) {
                    let currentIndex = null
                    this.employeesList.forEach((employee, index) => {
                        if (employee.id == item.employee_id) {
                            currentIndex = index
                        }
                    })
                    nextEmployee = this.employeesList[currentIndex + 1] || null
                }

                this.items.map(day => {
                    if ((this.listMode && nextEmployee && day.employee_id == nextEmployee.id) || (!this.listMode && day.date == nextDate)) {
                        if (!day.items[0].id) {
                            day.items = []
                        }
                        item.items.forEach(copied => {
                            if (this.listMode) {
                                day.items.push(
                                    {
                                        ...copied,
                                        id: null,
                                        employee_id: nextEmployee.id,
                                        dirty: true,
                                        changed: [],
                                        manager_approved_id: null,
                                        manager_approved_at: null,
                                        foreman_approved_id: null,
                                        foreman_approved_at: null,
                                    }
                                )
                            } else {
                                day.items.push(
                                    {
                                        ...copied,
                                        id: null,
                                        date: nextDate,
                                        dirty: true,
                                        changed: [],
                                        work_on_sunday: false,
                                        work_on_sunday_time: (this.isSunday(nextDate)) ? copied.work_minutes: null,
                                        manager_approved_id: null,
                                        manager_approved_at: null,
                                        foreman_approved_id: null,
                                        foreman_approved_at: null,
                                    }
                                )
                            }

                        })

                    }

                    return day
                })
            },
            addSubItem(item) {
                let employee_id = item.employee_id || item.items[0].employee_id

                item.items.push({
                    ...this.defaultRow,
                    date: item.date,
                    employee_id: employee_id,
                    activity_kz: this.getEmployeeById(employee_id).activity_kz ?? '1'
                })
            },
            deleteSubItem(item, subItem) {
                if (subItem.id) {
                    this.deletedIds.push(subItem.id)
                }
                item.items = item.items.filter(item => {
                    return item !== subItem
                })

                if (item.items.length == 0) {
                    this.addSubItem(item);
                }
            },
            getEmployeeName(id) {
                let employee = this.employeesList.filter(employee => {
                    return employee.id == id
                })[0]

                return employee ? employee.label : ''
            },
            getMe() {
                return this.$root.$children[0].me;
            },
            getEmployeeById(id) {
                if (id == this.getMe().id) {
                    return this.getMe()
                }
                const employee = this.employeesList.find(employee => employee.id == id) || null

                return employee
            },
            getCurrentEmployee() {
                if (this.selected_employee_id == this.getMe().id) {
                    return this.getMe()
                }
                const employee = this.employeesList.find(employee => employee.id == this.selected_employee_id) || null

                return employee
            },
        },
        computed: {
            employeesListSearchFiltered() {
                return this.employeesListSearch.filter(searchEmployee => {
                    return !this.employeesList.find(employee => employee.id == searchEmployee.id)
                })
            },
            totalHours() {
                let total = 0
                this.items.forEach(day => {
                    day.items.forEach(item => {
                        total += parseInt(item.work_minutes ?? 0)
                    })
                })

                return (total / 60).toFixed(2)
            },
            columns: function () {
                return this.headers
                    .filter(col => {
                        if (col.value == 'employee_id') {
                            return this.listMode
                        }
                        return true
                    })
                    .filter(col => {
                        if (col.permissions) {
                            if (col.permissions.includes(this.getMe().user.role)) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return true
                        }
                    })
                    .filter(col => {
                        if (this.detailed) {
                            return true
                        } else {
                            return col.minimal ? true : false
                        }
                    })
                    .filter(col => {
                        if (col.value == 'activity_kz') {
                            if (['MA'].includes(this.getMe().user.role)) {
                                return this.getMe().activity_admission
                            }
                        }
                        return true;
                    })
            },
        },
        watch: {
            'selectedIds': function () {
                this.$emit('selected', this.selectedIds)
            },
            'deletedIds': function () {
                this.$emit('deleted', this.deletedIds)
            },
        }
    }
</script>

<style>
    .employee-search .v-select__slot label {
        left: 0!important;
        right: auto!important;
        position: absolute!important;
        font-size: 12px;
        top: auto!important;
        font-weight: 400;
    }
</style>