<template>
    <div class="d-inline-flex">
        <div class="pr-2">
            <zw-time2 v-model="subItem.pause_start"
                      @change="onChange"
                      @input="setDirty"
                      :max="subItem.pause_end"
                      :readonly="readonly"
                      :allowed-minutes="allowedStep"
            ></zw-time2>
        </div>
        <div>
            <zw-time2 v-model="subItem.pause_end"
                      @change="onChange"
                      @input="setDirty"
                      :min="subItem.pause_start"
                      :readonly="readonly"
                      :allowed-minutes="allowedStep"
            ></zw-time2>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SlotPause',
        props: {
            item: Object,
            subItem: Object,
            readonly: Boolean
        },
        methods: {
            setDirty() {
                this.$set(this.subItem, 'dirty', true)
            },
            allowedStep: m => m % 15 === 0,
            onChange() {
                this.$emit('calculate', this.subItem)
            }
        }
    }
</script>