<template>
    <div>
        <zw-autocomplete v-model="subItem.state"
                         :items="$root.$children[0].getOptionsWithValue('state', subItem.state)"
                         item-text="label"
                         item-value="id"
                         cache-items
                         @input="setDirty"
                         dense outlined hide-details
                         :readonly="readonly"
                         :clearable="!readonly"
                         :name="name"
        ></zw-autocomplete>
    </div>
</template>

<script>
    export default {
        name: 'SlotState',
        props: {
            name: String,
            subItem: Object,
            readonly: Boolean
        },
        methods: {
            setDirty() {
                this.$set(this.subItem, 'dirty', true)
            },
        }
    }
</script>