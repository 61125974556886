<template>
    <div>
        <v-app-bar id="top-header"
                   class="top-header"
                   app
                   :style="$vuetify.breakpoint.smAndUp ? 'top:104px;height:auto' : 'top:57px;height:auto' "
                   height="170"
                   min-height="170"
        >
            <v-container>
                <v-row v-if="['MA', 'VE', 'A', 'PO', 'BA', 'BB', 'BD'].includes(getMe().user.role)">
                    <v-col cols="12" sm="2" v-if="['VE', 'A'].includes(getMe().user.role)">
                        <zw-autocomplete label="Betriebsstätten"
                                         v-model="filter.department_id"
                                         clearable
                                         item-text="label"
                                         item-value="id"
                                         :items="filterDepartmentsList"
                                         :custom-option-label="true"
                        ></zw-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="2" v-if="['PO', 'BA', 'BB', 'BD'].includes(getMe().user.role)">
                        <zw-autocomplete label="Baustellen"
                                         v-model="filter.construction_zone_id"
                                         clearable
                                         item-text="label"
                                         item-value="id"
                                         :items="filterConstructionZonesList"
                                         :custom-option-label="true"
                        ></zw-autocomplete>
                    </v-col>

                    <v-col v-if="!listMode && !['MA'].includes(getMe().user.role)" cols="12" sm="3">
                        <v-row>
                            <v-col cols="2">
                                <v-btn :disabled="!getPrev()"
                                       @click="selected_employee_id = getPrev().id"
                                       icon
                                       style="height:36px"
                                >
                                    <v-icon>mdi-arrow-left-drop-circle</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="8" class="text-center">
                                <zw-autocomplete label="Mitarbeiter"
                                                 v-model="selected_employee_id"
                                                 item-text="label"
                                                 item-value="id"
                                                 :items="employeesList"
                                ></zw-autocomplete>
                            </v-col>
                            <v-col cols="2">
                                <v-btn :disabled="!getNext()"
                                       @click="selected_employee_id = getNext().id"
                                       icon
                                       style="height:36px"
                                >
                                    <v-icon>mdi-arrow-right-drop-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                        <v-btn color="success"
                               @click="report()"
                        >Pdf Report
                        </v-btn>
                    </v-col>
                    <v-col v-if="!['MA'].includes(getMe().user.role)" class="text-right">
                        <v-btn v-if="!hasDirtyRows"
                               :disabled="!selectedIds.length"
                               color="success"
                               @click="approveItems()"
                        >Bestätigen
                        </v-btn>
                        <div v-else>Formulardaten vor der Freigabe speichern!</div>
                    </v-col>
                </v-row>

                <v-row v-if="getCurrentEmployee()">
                    <v-col cols="12" sm="4">
                        <v-toolbar-title>
                            <b>
                                <span v-if="listMode">{{day}} </span>
                                {{
                                new Date(year + '-' + (month + 1 < 10 ? '0' : '') + (month) + '-01')
                                .toLocaleString('de', {month: 'long'})
                                }}
                                {{ year }}
                            </b>
                            - {{ [getCurrentEmployee().firstname, getCurrentEmployee().lastname].join(' ') }}
                            {{ getCurrentEmployee().personal_number ? '(' + getCurrentEmployee().personal_number + ')' :
                            ''
                            }}
                        </v-toolbar-title>
                    </v-col>
                    <v-spacer></v-spacer>


                    <v-col v-if="listMode" style="max-width: 80px">
                        <v-select dense
                                  reverse
                                  v-model="day"
                                  :items="daysList"
                                  @change="dayChanged"
                                  single-line
                        ></v-select>
                    </v-col>
                    <v-col style="max-width: 150px">
                        <v-select dense
                                  reverse
                                  v-model="month"
                                  :items="months"
                                  @change="monthChanged"
                                  single-line
                        ></v-select>
                    </v-col>
                    <v-col style="max-width: 100px">
                        <v-select dense
                                  reverse
                                  v-model="year"
                                  :items="years"
                                  @change="yearChanged"
                                  single-line
                        ></v-select>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.smAndUp" style="max-width: 120px">
                        <v-switch
                                v-model="detailed"
                                dense
                                color="success"
                                class="text-uppercase"
                                label="Detail"
                        ></v-switch>
                    </v-col>
                    <v-col style="max-width: 170px;min-width: 170px">
                        <v-switch
                                v-model="opened"
                                dense
                                color="success"
                                class="text-uppercase"
                                label="Noch offen"
                        ></v-switch>
                    </v-col>
                    <v-col style="max-width: 220px;min-width: 220px" v-if="returnBack" class="text-right">
                        <v-btn @click="save(true)" color="secondary">Speichern + Zurück</v-btn>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.smAndUp" style="max-width: 150px" class="text-right">
                        <v-btn @click="save(false)" color="primary">Speichern</v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
        <v-overlay :value="loading">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>
        <template v-if="!loading">
            <validation-observer
                    ref="observer"
            >
                <v-overlay v-model="rendering">
                    <v-progress-circular
                            indeterminate
                            size="64"
                    ></v-progress-circular>
                </v-overlay>
                <v-row v-if="!rendering">
                    <v-col>
                        <div v-if="!$vuetify.breakpoint.smAndUp" class="custom-table">
                            <time-table-mobile :items="filteredItems"
                                               :callback="()=>{calculateData()}"
                                               :loading="loading"
                                               :construction-zones-list="constructionZonesList"
                            ></time-table-mobile>
                        </div>
                        <div v-if="$vuetify.breakpoint.smAndUp" class="custom-table">
                            <time-table :detailed="detailed"
                                        :items="filteredItems"
                                        :loading="loading"
                                        :list-mode="listMode"
                                        :construction-zones-list="constructionZonesList"
                                        :employees-list="employeesList"
                                        :employees-list-search="employeesListSearch"
                                        @selected="(value)=>{selectedIds=value}"
                                        @deleted="(value)=>{deletedIds=value}"
                                        @append-employee="appendEmployee"
                            ></time-table>
                        </div>
                    </v-col>
                </v-row>
            </validation-observer>
        </template>
    </div>
</template>

<script>
    import worklogsCalculations from '@/mixins/worklogsCalculations'
    import worklogs from '@/mixins/worklogs'
    import worklogsCommon from '@/mixins/worklogsCommon'
    import {fetchEmployee, employeesList, pdfReport} from './../graphql/employee'
    import {constructionZonesList} from './../graphql/constructionZone'
    import {workLogsSave, workLogsApprove} from './../graphql/worklog'
    import {departmentsList} from './../graphql/department'
    import TimeTable from "../components/TimeTable";
    import TimeTableMobile from "../components/TimeTableMobile";

    export default {
        components: {TimeTableMobile, TimeTable},
        mixins: [worklogsCommon, worklogsCalculations, worklogs],
        data() {
            return {
                loading: true,
                listMode: false,
                selectedIds: [],
                filterConstructionZonesList: [],
                filterDepartmentsList: [],
                filter: {
                    construction_zone_id: null,
                    department_id: null,
                },
                detailed: false,
                opened: false,
                selected_employee_id: null,
                employeesList: [],
                employeesListSearch: [],
                constructionZonesList: [],
                deletedIds: [],
                returnBack: false,
            }
        },
        methods: {
            appendEmployee(employeeId) {
                const employee = this.employeesListSearch.find(employee => employee.id == employeeId)
                this.employeesList.push(employee)

                const selectedDate = this.year + '-' + this.month + '-' + this.day

                let items = []
                items.push({
                    date: selectedDate,
                    employee_id: employeeId,
                    activity_kz: this.getEmployeeById(employeeId).activity_kz ?? '1'
                })

                this.tableData.push({
                    employee_id: employeeId,
                    date: this.selectedDate,
                    items: items,
                })
            },
            getCurrentEmployeeName() {
                let employee = this.employeesList.filter(employee => {
                    return employee.id == this.selected_employee_id
                })[0]

                return employee ? employee.label : ''
            },
            getCurrentIndex() {
                let currentIndex = null
                this.employeesList.forEach((employee, index) => {
                    if (employee.id == this.selected_employee_id) {
                        currentIndex = index
                    }
                })

                return currentIndex
            },
            getNext() {
                return this.employeesList[this.getCurrentIndex() + 1] ?? null
            },
            getPrev() {
                return this.employeesList[this.getCurrentIndex() + -1] ?? null
            },
            report() {
                this.$apollo.query({
                    query: pdfReport,
                    variables: {
                        employee_id: parseInt(this.selected_employee_id),
                        date: this.year + '-' + this.month + '-' + this.day,
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    var a = document.createElement("a")
                    a.href = "data:application/pdf;base64," + data.report
                    a.download = "report.pdf"
                    a.click()
                })
            },
            approveItems() {
                this.$apollo.mutate({
                    mutation: workLogsApprove,
                    variables: {
                        worklogs: this.selectedIds,
                    },
                }).then(() => {
                    this.calculateData()
                })
            },
            save(goBack = false) {
                this.$refs.observer.validate().then(valid => {
                    if (valid) {
                        this.loading = true
                        let worklogs = []
                        this.tableData.forEach(date => {
                            date.items.forEach(item => {
                                if (item.dirty) {
                                    delete item.__typename
                                    delete item.managerApproved
                                    delete item.adminApproved
                                    delete item.foremanApproved
                                    delete item.changed
                                    delete item.readonly
                                    worklogs.push(item)
                                }
                            })
                        })

                        this.deletedIds.forEach(id => {
                            worklogs.push({id, delete: true})
                        })

                        this.$apollo.mutate({
                            mutation: workLogsSave,
                            variables: {
                                worklogs: worklogs,
                            },
                        }).then(() => {
                            this.$root.$children[0].successMessage()

                            if (goBack) {
                                this.$router.push({name: 'Checkoff', 'query': {'return': 1}})
                            }
                            this.calculateData()
                        }).catch((error) => {
                            this.$root.$children[0].errorMessage(error.graphQLErrors[0].message)
                        }).finally(() => {
                            this.loading = false
                        })
                    } else {
                        this.$root.$children[0].validationMessage()
                    }
                })
            },
            loadEmployee(employeeId) {
                this.loading = true
                this.$apollo.query({
                    query: fetchEmployee,
                    variables: {
                        id: parseInt(employeeId),
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    if (!this.employeesList.find(employee => employee.id == data.employee.id)) {
                        data.employee.label = data.employee.firstname + ' ' + data.employee.lastname + ' ' + data.employee.personal_number
                        this.employeesList.push(data.employee)
                    }

                    this.$apollo.query({
                        query: constructionZonesList,
                        fetchPolicy: 'network-only'
                    }).then(({data}) => {

                        this.constructionZonesList = data.constructionZonesList.map(zone => {
                            zone.label = zone.construction_zone_number
                            zone.custom_label = zone.construction_zone_number + ' ' + zone.name
                            return zone
                        })
                        this.calculateData(() => {
                            this.loading = false
                        })
                    })
                }).finally(() => {
                    this.$root.$children[0].bottomMenu = [
                        {
                            icon: 'add',
                            event: () => {
                                this.$root.$children[0].$refs['worklog-form'].open(null, this.selected_employee_id, null, false, () => {
                                    this.calculateData()
                                })
                            }
                        }
                    ]
                })
            },
            fetchEmployeesList() {
                if (this.filter.construction_zone_id == null && this.filter.department_id == null) {
                    return
                }
                if (this.listMode) {
                    this.fetchEmployeesListWithoutDate()
                }
                this.$apollo.query({
                    query: employeesList,
                    variables: {
                        date: this.listMode ? this.year + '-' + this.month + '-' + this.day : null,
                        construction_zone_id: this.filter.construction_zone_id,
                        department_id: this.filter.department_id,
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.employeesList = data.employeesList.map(employee => {
                        return {
                            ...employee,
                            label: employee.firstname + ' ' + employee.lastname + ' ' + employee.personal_number
                        }
                    })

                    if (this.listMode) {
                        this.calculateData()
                    }
                })
            },
            fetchEmployeesListWithoutDate() {
                if (this.filter.construction_zone_id == null && this.filter.department_id == null) {
                    return
                }
                this.$apollo.query({
                    query: employeesList,
                    variables: {
                        construction_zone_id: this.filter.construction_zone_id,
                        department_id: this.filter.department_id,
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.employeesListSearch = data.employeesList.map(employee => {
                        return {
                            ...employee,
                            label: employee.firstname + ' ' + employee.lastname + ' ' + employee.personal_number
                        }
                    })
                })
            },
        },
        computed: {
            hasDirtyRows() {
                let isDirty = false
                this.tableData.forEach(day => {
                    day.items.forEach(item => {
                        if (item.dirty) {
                            isDirty = true
                        }
                    })
                })
                return isDirty
            },
        },
        mounted: function () {
            if (this.$route.meta.listMode) {
                this.listMode = true
            }
            if (['VE', 'A', 'PO', 'BA', 'BB', 'BD'].includes(this.getMe().user.role)) {
                this.$apollo.query({
                    query: constructionZonesList,
                    variables: {
                        use: true,
                    },
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.filterConstructionZonesList = data.constructionZonesList.map(zone => {
                        zone.label = zone.construction_zone_number
                        zone.custom_label = zone.construction_zone_number + ' ' + zone.name
                        return zone
                    })
                })
                this.$apollo.query({
                    query: departmentsList,
                    fetchPolicy: 'network-only'
                }).then(({data}) => {
                    this.filterDepartmentsList = data.departmentsList.map(department => {
                        department.label = department.number
                        department.custom_label = department.number + ' ' + department.name
                        return department
                    })
                })
            }
            let employeeId = this.$route.query.employee_id ?? this.getMe().id

            if(this.$route.query.employee_id) {
                this.returnBack = true
                this.detailed = true
            }

            let filter = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null
            if (employeeId && filter) {
                this.year = filter.year
                this.month = filter.month
                this.filter.construction_zone_id = filter.filter.construction_zone_id
                this.filter.department_id = filter.filter.department_id
                // this.opened = filter.opened
                // this.employeesList = filter.employees_list
            }

            this.selected_employee_id = '' + employeeId

            this.loadEmployee(employeeId)

        },
        watch: {
            'filter.construction_zone_id': function () {
                this.fetchEmployeesList()
            },
            'filter.department_id': function () {
                this.fetchEmployeesList()
            },
            'selected_employee_id': function (val) {
                this.loadEmployee(val)
            }
        },
    }
</script>

<style lang="scss">
    .custom-table td {
        padding: 0 0 !important;
    }

    .custom-table {
        .v-text-field input {
            padding: 4px 0;
        }

        .table-item {
            min-height: 33px;
            padding: 0 2px;
        }

        .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td {
            font-size: 12px;
        }
    }

    .fill-width {
        overflow-x: auto;
        flex-wrap: nowrap;
    }

    .vertical-bottom {
        vertical-align: bottom;
    }

    .max-60p {
        max-width: 60%;
    }

    .min-width-300 {
        min-width: 300px;
    }

    .v-select__selection {
        max-width: 100%;
    }

    .v-select__slot {
        min-height: 28px;
    }

    .custom-table thead th {
        position: sticky;
        z-index: 2;
    }

    .custom-table thead th:first-child {
        left: 0px;
        z-index: 3;
    }

    tbody tr td:first-child {
        position: sticky;
        background: white;
        z-index: 1;
        left: 0px;
    }

    .v-data-table__wrapper {
        overflow-x: unset;
        overflow-y: unset;
    }

    .v-input__slot {
        min-height: 28px !important;
    }

    .v-input input {
        max-height: 28px !important;
    }

    .top-header .v-toolbar__content {
        height: auto !important;
    }

    .custom-table {
        .v-data-table {
            th {
                top: var(--header-top);
            }
        }
    }

    .v-main.padding {
        padding-top: var(--header-top) + ' !important';
    }

    .v-main.mobile-padding {
        padding-top: var(--header-top) + ' !important';
    }

    @media all and (min-width: 0px) and (max-width: 599px) {
        .v-input__slot {
            height: 40px !important;
        }
        .v-select__slot {
            height: 40px !important;
        }
    }
</style>